<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <!-- ปุ่มเลือกสินค้า -->
          <v-select dense outlined hide-details label="ช่วงเวลา" :items="year" class="me-2"></v-select>
          <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
        </div>
        <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
          <v-icon class="me-2">
            {{ icons.mdiCheck }}
          </v-icon>
          รันรีพอร์ต
        </v-btn>
      </div>
      <hr class="mt-5 mb-5" />
      <h2 class="text-center">กระทบยอดธนาคาร</h2>
      <h3 class="text-center">{{ name }}</h3>
      <p class="text-center mb-0 mt-2">1/1/{{ new Date().getFullYear() }} - {{ currentDate() }}</p>
      <hr class="mt-5 mb-5" />
      <template>
        <v-data-table :headers="headers" :items="desserts" :items-per-page="5" class="elevation-1"></v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mdiCheck } from '@mdi/js'

export default {
  components: { DatePicker },
  setup() {
    const year = [
      'เลือกช่วงเวลา',
      'กำหนดเอง',
      'เดือนนี้',
      'ไตรมาสนี้',
      ' ปีปัจจุบัน',
      'ปีก่อนหน้า',
      'เดือนนี้ถึงวันนี้',
      'ไตรมาสนี้ถึงวันนี้',
      'ปีนี้ถึงวันนี้',
      'ปีงบประมาณปัจจุบัน',
      'ปีงบประมาณก่อนหน้า',
      '12 เดือนย้อนหลัง',
    ]

    return {
      year,
      icons: {
        mdiCheck,
      },
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      name: 'Case',
      dateTime: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'asset',
        },
        { text: 'เลขที่เอกสาร', value: 'numberDoc' },
        { text: 'รายละเอียด', value: 'details' },
        { text: 'ฝาก', value: 'deposit' },
        { text: 'ถอน', value: 'withdraw' },
        { text: 'คงเหลือ', value: 'total' },
        { text: 'สถานะ', value: 'status' },
      ],
      desserts: [
        {
          asset: 'สินทรัพย์',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
        {
          asset: 'หนี้สิน',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
        {
          asset: 'ส่วนของผู้ถือหุ้น',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
        {
          asset: 'รายได้',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
        {
          asset: 'ค่าใช้จ่าย',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
        {
          asset: 'ภาษีได้นิติบุคคล',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
        {
          asset: 'ภาษีได้นิติบุคคล',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
        {
          asset: 'ภาษีได้นิติบุคคล',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
        {
          asset: 'ภาษีได้นิติบุคคล',
          numberDoc: 159,
          details: 'รายละเอียด',
          deposit: 24,
          withdraw: 7,
          total: 30,
          status: '-',
        },
      ],
    }
  },

  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.bannerText {
  background-color: #8a8d93;
  width: 30rem;
  height: 4.5rem;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.bannerText h2,
.bannerText p {
  color: #f5f5f5 !important;
}
</style>
