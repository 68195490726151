<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <!-- <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon> -->
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <Experiment />
      </v-tab-item>

      <v-tab-item>
        <Iedger />
      </v-tab-item>

      <v-tab-item>
        <DailyBook />
      </v-tab-item>

      <v-tab-item>
        <Impact />
      </v-tab-item>

      <v-tab-item>
        <BusinessAsset />
      </v-tab-item>

      <v-tab-item>
        <BusinessCreditor />
      </v-tab-item>

      <v-tab-item>
        <BusinessDebtor />
      </v-tab-item>

      <v-tab-item>
        <Purchase />
      </v-tab-item>

      <v-tab-item>
        <BusinessExpenses />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiCartOutline, mdiCartPlus, mdiMore } from '@mdi/js'
import { ref } from '@vue/composition-api'

// pages
import Experiment from '../business-data/Experiment.vue'
import Iedger from '../business-data/Iedger.vue'
import DailyBook from '../business-data/DailyBook.vue'
import Impact from '../business-data/Impact.vue'
import BusinessAsset from '../business-data/BusinessAsset.vue'
import BusinessCreditor from '../business-data/BusinessCreditor.vue'
import BusinessDebtor from '../business-data/BusinessDebtor.vue'
import Purchase from '../business-data/Purchase.vue'
import BusinessExpenses from '../business-data/BusinessExpenses.vue'

export default {
  components: {
    Experiment,
    Iedger,
    DailyBook,
    Impact,
    BusinessAsset,
    BusinessCreditor,
    BusinessDebtor,
    Purchase,
    BusinessExpenses,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'งบทดลอง', icon: mdiCartOutline },
      { title: 'บัญชีแยกประเภท', icon: mdiCartPlus },
      { title: 'สมุดรายวัน', icon: mdiMore },
      { title: 'กระทบยอด', icon: mdiMore },
      { title: 'สินทรัพย์', icon: mdiMore },
      { title: 'เจ้าหนี้', icon: mdiMore },
      { title: 'ลูกหนี้', icon: mdiMore },
      { title: 'รายการซื้อ', icon: mdiMore },
      { title: 'ค่าใช้จ่าย', icon: mdiMore },
    ]

    return {
      tab,
      tabs,
    }
  },
  data: () => ({
    items: [
      {
        text: 'สินค้า',
        disabled: false,
        href: 'product',
      },
      {
        text: 'สต๊อก',
        disabled: true,
        href: 'stock',
      },
    ],
  }),
}
</script>

<style></style>
