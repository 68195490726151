<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <!-- ปุ่มเลือกสินค้า -->
          <v-select dense outlined hide-details label="ช่วงเวลา" :items="year" class="me-2"></v-select>
          <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
        </div>
        <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
          <v-icon class="me-2">
            {{ icons.mdiCheck }}
          </v-icon>
          รันรีพอร์ต
        </v-btn>
      </div>
      <hr class="mt-5 mb-5" />
      <v-card-title>
        <div>
          <h3>บัญชีแยกประเภท</h3>
          <h5 class="mt-2">{{ name }}</h5>
          <h5 class="font-weight-regular mb-0 mt-2">1/1/{{ new Date().getFullYear() }} - {{ currentDate() }}</h5>
        </div>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <hr class="mt-5 mb-5" />
      <v-card>
        <v-data-table :headers="headers" :items="desserts" :search="search"></v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mdiCheck } from '@mdi/js'

export default {
  components: { DatePicker },
  setup() {
    const year = [
      'เลือกช่วงเวลา',
      'กำหนดเอง',
      'เดือนนี้',
      'ไตรมาสนี้',
      ' ปีปัจจุบัน',
      'ปีก่อนหน้า',
      'เดือนนี้ถึงวันนี้',
      'ไตรมาสนี้ถึงวันนี้',
      'ปีนี้ถึงวันนี้',
      'ปีงบประมาณปัจจุบัน',
      'ปีงบประมาณก่อนหน้า',
      '12 เดือนย้อนหลัง',
    ]

    return {
      year,
      icons: {
        mdiCheck,
      },
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      name: 'Case',
      dateTime: '',
      search: '',
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'asset',
        },
        { text: 'เลขที่เอกสาร', value: 'numberDoc' },
        { text: 'เลขที่สมุดบัญชี', value: 'numberAccount' },
        { text: 'รายละเอียด', value: 'discription' },
        { text: 'เดบิต', value: 'debit' },
        { text: 'เครดิต', value: 'credit' },
        { text: 'รวม', value: 'total' },
      ],
      desserts: [
        {
          asset: 'เงินสด',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินสดย่อย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'บัญชีเงินฝากระหว่างทาง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินลงทุนระยะสั้น',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ลูกหนี้การค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เช็ครับลงวันที่ล่วงหน้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเผื่อหนี้สงสัยจะสูญ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ลูกหนี้การค้าต่างประเทศ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'วัตถุดิบคงเหลือ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'สินค้าสำเร็จรูปคงเหลือ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'งานระหว่างทำ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'วัสดุสิ้นเปลืองคงเหลือ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายล่วงหน้า-ค่าสินค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีนิติบุคคลจ่ายล่วงหน้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายจ่ายล่วงหน้า-ค่าเช่า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายจ่ายล่วงหน้า-อื่น ๆ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินทดลองจ่ายพนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ดอกเบี้ยค้างรับ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้ค้างรับอื่น',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้ค้างรับอื่นต่างประเทศ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีซื้อ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีซื้อ-ยังไม่ถึงกำหนด',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ลูกหนี้-กรมสรรพากร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีซื้อ-รอเครดิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีหัก ณ ที่จ่ายที่ใช้สิทธิ์ได้',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ลูกหนี้เงินให้กู้ยืม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ที่ดิน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'อาคาร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'อุปกรณ์สำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เคร่ืองตกแต่งสำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ยานพาหนะ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'คอมพิวเตอร์ และอุปกรณ์อิเล็กทรอนิกส์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ส่วนปรับปรุงสำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เครื่องมือ/เครื่องจักร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาสะสม-ที่ดิน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาสะสม-อาคาร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาสะสม-อุปกรณ์สำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาสะสม-เครื่องตกแต่งสำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาสะสม-ยานพาหนะ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาสะสม-คอมพิวเตอร์ และอุปกรณ์อิเล็กทรอนิกส์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาสะสม-ส่วนปรับปรุงสำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาสะสม-เครื่องมือ/เครื่องจักร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'กรมธรรม์ประกันอัคคีภัย-สินค้าและอาคาร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'กรมธรรม์ประกันอัคคีภัย-ยานพาหนะ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'กรมธรรม์ประกันอุบัติเหตุพนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าความนิยม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าความนิยม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินมัดจำ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เจ้าหนี้การค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เช็คจ่ายล่วงหน้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เจ้าหนี้การค้าต่างประเทศ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินเดือนค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินโบนัสค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่านายหน้าค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินประกันสังคมรอนำส่ง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าโทรศัพท์ค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าไฟฟ้าค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าน้ำประปาค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ดอกเบี้ยเงินกู้ค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายค้างจ่าย-อื่น ๆ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายต่างประเทศ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีหัก ณ ที่จ่ายค้างจ่าย ภงด.1',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีหัก ณ ที่จ่ายค้างจ่าย ภงด.2',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีหัก ณ ที่จ่ายค้างจ่าย ภงด.3',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีหัก ณ ที่จ่ายค้างจ่าย ภงด.53',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีเงินได้นิติบุคคลค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้รับล่วงหน้า-ค่าสินค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้รับล่วงหน้า-ค่านายหน้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินมัดจำรับ และเงินค้ำประกัน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีขาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีขาย-รอเรียกเก็บ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เจ้าหนี้กรมสรรพากร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เจ้าหนี้กรมสรรพากร-ภ.พ.36',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินกู้ยืมจากกรรมการ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินกู้ยืมธนาคารระยะยาว',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินปันผลค้างจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินประกันการทำงานพนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ทุน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'กำไรสะสม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'กำไร(ขาดทุน)',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินปันผล',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินได้จากการขาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินได้จากการให้บริการ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รับคืนสินค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ส่วนลดจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้จากการขายต่างประเทศ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้จากการให้บริการในต่างประเทศ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ดอกเบี้ยรับ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'กำไร(ขาดทุน)จากการจำหน่ายทรัพย์สิน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ส่วนต่างจากการชำระเงิน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'กำไรจากอัตราแลกเปลี่ยน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้จากการขายเศษวัศดุ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้จากให้เช่าที่จอดรถ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้บริการให้คำปรึกษา',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้จากการจัดส่ง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายได้อื่น ๆ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ต้นทุนสินค้าเพื่อขาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ต้นทุนค่าส่งสินค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ซื้อ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ส่วนลดรับ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ส่งคืนสินค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ส่วนต่างจากการคำนวณต้นทุน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ต้นทุนงานบริการ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าจ้างงานบริการ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าจ้างทำโปรเจค',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่านายหน้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าโฆษณา',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าสินค้าตัวอย่าง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าส่งเสริมการขาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าขนส่ง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่ารับรอง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าประกันภัยสินค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายในการขาย-ค่าติดต่อสื่อสาร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายเดินทาง และยานพาหนะ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าวิจัยตลาด',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินเดือนฝ่ายขาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินเดือน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยเลี้ยง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'โบนัส',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเดินทาง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'คอมมิชชั่น',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าล่วงเวลา',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าที่พักอาศัย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่ารักษาพยาบาล',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าอบรมสัมนา',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกันชีวิต และอุบัติเหตุ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าอาหาร และเครื่องดื่ม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าจัดเลี้ยง และสันทนาการ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าสวัสดิการอื่น ๆ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินสมทบกองทุนประกันสังคม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินสมทบกองทุนทดแทน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีเงินได้',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'หักเงินมาสาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'หักค่าใช้จ่ายอื่น ๆ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเครื่องเขียนแบบพิมพ์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าซ่อมแซม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'วัสดุสินเปลือง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าวารสาร และสมาชิก',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเช่าสำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่ายามรักษาความปลอดภัย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่ารักษาความสะอาด',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าบริการวิชาชีพ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าบริการอื่น',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าโฆษณาอื่น',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าโทรศัพท์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าไฟฟ้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าน้ำประปา',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าไปรษณีย์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าอินเตอร์เน็ต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคา-ที่ดิน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคา-อาคาร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคา-อุปกรณ์สำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคา-เครื่องตกแต่งสำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคา-ยานพาหนะ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคา-คอมพิวเตอร์ และอุปกรณ์อิเล็กทรอนิกส์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคา-ส่วนปรับปรุงสำนักงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกัน-อาคาร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกัน-อุปกรณ์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกัน-เครื่องตกแต่ง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกัน-ยานพาหนะ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีบำรุงท้องที่ และภาษีโรงเรือน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าภาษียานพาหนะ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีป้าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าธรรมเนียมธนาคาร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าตรวจสอบบัญชี และปรึกษากฏหมาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าธรรมเนียมหนังสือค้ำประกัน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าธรรมเนียมอื่น',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าบริการทำบัญชี',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าบริการอื่น',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ดอกเบี้ยจ่าย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'หนี้สงสัยจะสูญ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่ารับรอง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าบริจาคการกุศล',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ส่วนลดจากการขายลดเช็ค',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายเบ็ดเตล็ด',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'กำไรขาดทุนจากอัตราแลกเปลี่ยน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินขาดเกินบัญชี',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ขาดทุนจากสินค้าสูญหาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ขาดทุนจากสินค้าชำรุดเสียหาย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'หนี้สูญ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีซื้อต้องห้ามหักรายจ่ายไม่ได้',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีซื้อต้องห้ามหักรายจ่ายได้',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีซื้อไม่ใช้สิทธิ',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีซื้อขอคืนไม่ได้',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เบี้ยปรับเงินเพิ่ม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'รายจ่ายต้องห้าม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าภาษีออกแทน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินเดือนฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค้าเบี้ยเลี้ยงฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'โบนัสฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเดินทางฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าล่วงหน้าเวลาฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าที่พักอาศัยฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่ารักษาพยาบาลฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าอบรมสัมนาฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกันชีวิต และอุบัติเหตุฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าอาหาร และเครื่องดื่มฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าจัดเลี้ยง และสันทนาการการฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าสวัสดิการอื่น ๆ (ฝ่ายผลิต)',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินสมทบกองทุนประกันสังคมฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'เงินสมทบกองทุนทดแทนฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'หักเงินมาสายฝ่ายผลิต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'หักค่าใช้จ่ายอื่น ๆ (ฝ่ายผลิต)',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเสื่อมราคาเครื่องจักร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'วัสดุซ่อมบำรุง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'วัสดุสิ้นเปลือง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าซ่อมแซม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกัน-เครื่องจักร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'วัสดุสิ้นเปลือง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าซ๋อมแซม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกัน-เครื่องจักร',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'วัสดุสิ้นเปลือง',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าซ่อมแซม',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเช่าโรงงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่ายามรักษาความปลอดภัย',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่ารักษาความสะอาด',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าเบี้ยประกัน-โรงงาน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าโทรศัพท์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าไฟฟ้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าน้ำประปา',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าไปรษณีย์',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าอินเตอร์เน็ต',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าขนส่งสินค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าจ้างเหมา',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าแรงงานตรงจากแผนต้นทุน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ค่าใช้จ่ายการผลิตจากแผนต้นทุน',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'ภาษีเงินได้นิติบุคคล',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'บัญชีพัก-เพิ่มสินค้า',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
        {
          asset: 'บัญชีพัก',
          numberDoc: 159,
          numberAccount: 6,
          discription: 'ระบุรายละเอียด',
          debit: 7,
          credit: 30,
          total: 3,
        },
      ],
    }
  },

  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      return date
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.bannerText {
  background-color: #8a8d93;
  width: 30rem;
  height: 4.5rem;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.bannerText h2,
.bannerText p {
  color: #f5f5f5 !important;
}
</style>
